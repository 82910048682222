<template>
  <b-overlay
      :show="loadingOverlay"
      rounded="sm"
  >
    <div v-if="Properties !== null">
      <b-modal
          id="modal-delete"
          cancelTitle="خیر"
          centered
          no-close-on-backdrop
          ok-title="بله"
          @ok="DeleteProperty"
      >
        <span>آیا از حذف کردن این جزئیات اطمینان دارید ؟ </span>
      </b-modal>
      <b-modal
          id="modal-edit"
          cancelTitle="خیر"
          centered
          no-close-on-backdrop
          ok-title="بروزرسانی جزئیات"
          @ok.preventDefault()="UpdateProperty"
      >
        <b-row v-if="SelectedProperty!==null">
          <b-col cols="12">
            <b-form-group>
              <label>نام جزئیات</label>
              <b-form-input
                  v-model="SelectedProperty.name"
                  placeholder="نام جزئیات"
                  type="text"
              />
            </b-form-group>
            <!--            <b-form-group>-->
            <!--              <label for="email">جزئیات اصلی</label>-->
            <!--              <v-select-->
            <!--                  v-model="SelectedCategory.parentId"-->
            <!--                  :options="ProductCategories"-->
            <!--                  label="name"-->
            <!--                  :reduce="name => name.productCategoryId"-->
            <!--                  class="per-page-selector d-inline-block w-100"-->
            <!--              />-->
            <!--            </b-form-group>-->
          </b-col>
        </b-row>


      </b-modal>
      <b-modal
          id="modal-Add"
          cancelTitle="خیر"
          centered
          no-close-on-backdrop
          ok-title="افزودن جزئیات"
          @ok.preventDefault()="CreateProperty"
      >
        <b-row>
          <b-col cols="12">
            <b-form-group>
              <label for="email">نام جزئیات</label>
              <b-form-input
                  v-model="propertyName"
                  placeholder="نام جزئیات"
                  type="text"
              />
            </b-form-group>
            <b-form-group>
              <label for="email">دسته بندی</label>
              <v-select
                  v-model="SelectedCategoryId"
                  :options="Categories"
                  :reduce="name => name.academyCategoryId"
                  class="per-page-selector d-inline-block w-100"
                  label="name"
              />
            </b-form-group>
          </b-col>

        </b-row>


      </b-modal>
      <!-- Table Container Card -->
      <b-card
          class="mb-0"
          no-body
      >
        <b-col class="my-2" lg="12" md="12">
          <b-row>
            <b-col class="my-2" lg="8" md="12">
              <debouncer :placeHolder="`جستجو بر اساس نام دسته بندی`" @setValue="getsearchValue"></debouncer>

            </b-col>
            <b-col class="my-2" lg="2" md="12">
              <b-button
                  class="w-100"
                  v-b-modal.modal-Add
                  variant="success"

              >
                افزودن جزئیات جدید
              </b-button>
            </b-col>
            <b-col class="my-2" lg="2" md="12">
              <clear-sorts-btn @clear="sortBy = ''"></clear-sorts-btn>
            </b-col>
          </b-row>
        </b-col>


        <b-table
            ref="refUserListTable"
            :fields="myTableColumns"
            :items="Properties"
            bordered
            :sort-by.sync="sortBy"
            class="position-relative"
            empty-text="موردی موجود نیست!"
            primary-key="id"
            responsive
            show-empty
            striped
        >

          <!-- Column: delete -->

          <template #cell(operation)="data">
            <div class="d-flex align-items-center justify-content-between">

              <div v-b-modal.modal-delete
                   class="cursor-pointer d-flex flex-row"
                   @click="SetSelectedProperty(data.item)"
              >
                <feather-icon class="text-danger" icon="TrashIcon" size="20"/>
              </div>
              <div v-b-modal.modal-edit
                   class="cursor-pointer d-flex flex-row"
                   @click="SetSelectedProperty(data.item)"
              >
                <feather-icon class="text-primary" icon="EditIcon" size="20"/>
              </div>
            </div>
          </template>


        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <!-- Pagination -->
            <b-col
                class="d-flex align-items-center justify-content-end "
                cols="12"
                sm="12"
            >

              <b-pagination
                  v-model="currentPage"
                  :per-page="perPage"
                  :total-rows="totalCount"
                  class="mb-0 mt-1 mt-sm-0"
                  first-number
                  last-number
                  next-class="next-item"
                  prev-class="prev-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon"
                                size="18"
                                style="transform: rotate(180deg)"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>
      </b-card>
    </div>


  </b-overlay>
</template>

<script>

import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BFormFile,
  BBadge, BDropdown, BDropdownItem, BPagination, BOverlay, BModal, BFormGroup, BFormSelect, BFormCheckbox
} from 'bootstrap-vue'
import vSelect from 'vue-select'

import {GetAllAcademyCategory} from "@/libs/Api/academyCategory";
import {GetAllProperties, CreateProperty, DeleteProperty, UpdateProperty} from "@/libs/Api/academyCategoryProperties";

import ToastificationContent from '@core/components/toastification/ToastificationContent'
import {getUserData} from "@/auth/utils";
import ClearSortsBtn from "@/views/components/clearSortsBtn.vue";
import Debouncer from "@/views/components/debouncer.vue";

export default {
  title: 'لیست جزئیات دوره ها  - پنل ادمین انزا مد',
  name: 'UsersList',
  data() {
    return {
      Properties: null,
      totalCount: null,
      sortBy: '',
      Categories: null,
      loadingOverlay: false,
      perPageOptions: [10, 20, 30, 40, 50],
      myTableColumns: [
        {
          key: 'academyCategoryPropertyId',
          label: 'شناسه',
          sortable: true
        },
        {
          key: 'name',
          label: 'نام',
          sortable: true
        },
        {
          key: 'catName',
          label: 'نام دسته بندی',
          sortable: true
        },
        {
          key: 'operation',
          label: 'عملیات'
        },

      ],
      currentPage: 1,
      perPage: 10,
      propertyName: '',
      search: '',
      SelectedProperty: null,
      SelectedCategoryId: 0

    }
  },
  async created() {
    await this.GetAllProperties();
    await this.GetAllCategories();
  },
  components: {
    Debouncer,
    ClearSortsBtn,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BFormCheckbox,
    BFormFile,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BOverlay,
    BModal,
    BFormSelect,
    vSelect,
  },

  watch: {

    currentPage: function (val) {
      this.GetAllProperties();
    }
  },
  methods: {
    getsearchValue(val) {
      this.search = val
      this.GetAllProperties();
    },
    async UpdateProperty() {
      let _this = this
      _this.loadingOverlay = true

      let updateProperty = new UpdateProperty(_this)
      let data = {
        academyCategoryPropertyId: this.SelectedProperty.academyCategoryPropertyId,
        categoryId: this.SelectedProperty.categoryId,
        name: this.SelectedProperty.name
      }
      updateProperty.setData(data)
      await updateProperty.fetch(function (content) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `جزئیات بروزرسانی شد`,
          },
        })
        _this.SelectedProperty = null

        _this.GetAllProperties();
      }, function (error) {
        console.log(error)
      })
      _this.loadingOverlay = false


    },
    async DeleteProperty() {
      let _this = this
      _this.loadingOverlay = true

      let deleteProperty = new DeleteProperty(_this)
      deleteProperty.setParams({id: this.SelectedProperty.academyCategoryPropertyId})
      await deleteProperty.fetch(function (content) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: ` جزئیات دسته بندی  حذف شد.`,
          },
        })
        _this.GetAllProperties();
      }, function (error) {
        console.log(error)
      })
      _this.loadingOverlay = false

    },
    SetSelectedProperty(item) {
      this.SelectedProperty = JSON.parse(JSON.stringify(item))
    },
    async GetAllProperties() {
      let _this = this
      _this.loadingOverlay = true
      let getAllProperties = new GetAllProperties(_this)
      let data = {
        pageNumber: this.currentPage,
        searchCommand: this.search,
        count: 10
      }
      getAllProperties.setParams(data)
      await getAllProperties.fetch(function (content) {
        _this.Properties = content.categoryProperties
        _this.totalCount = content.count
      }, function (error) {
        console.log(error)
      })
      _this.loadingOverlay = false

    },
    async GetAllCategories() {
      let _this = this
      let getAllAcademyCategory = new GetAllAcademyCategory(_this)
      let data = {
        pageNumber: 0,
        search: '',
        count: 0
      }
      getAllAcademyCategory.setParams(data)
      await getAllAcademyCategory.fetch(function (content) {
        _this.Categories = content.academyCategories

      }, function (error) {
        console.log(error)
      })
    },
    async CreateProperty() {
      let _this = this
      if (_this.propertyName !== '' && _this.SelectedCategoryId !== 0) {
        _this.loadingOverlay = true


        let createProperty = new CreateProperty(_this)
        let data = {
          categoryId: this.SelectedCategoryId,
          name: this.propertyName
        }
        createProperty.setData(data)
        await createProperty.fetch(function (content) {
          _this.$toast({
            component: ToastificationContent,
            position: 'bottom-center',
            props: {
              title: `عملیات موفق`,
              icon: 'CheckIcon',
              variant: 'success',
              text: `جزئیات جدید ساخته شد`,
            },
          })
          _this.GetAllProperties();
          _this.propertyName = ''
          _this.SelectedCategoryId = 0
        }, function (error) {
          console.log(error)
        })
      } else {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات ناموفق`,
            icon: 'CloseIcon',
            variant: 'danger',
            text: `لطفا همه فیلد هارا پر کنید`,
          },
        })
      }
      _this.loadingOverlay = false
    },
  },
}
</script>

<style scoped>

</style>
